import React from 'react';
import styled from 'styled-components';
import { withPage } from '../Page';
import * as L from '../Utils/Lang';
import * as Widget from '../Components/Widget';

class ExpiredPage extends React.Component {
  render() {
    return (
      <Widget.Center
        align='center'
        justify='center'
        extraCss='flex: 1; min-height: 400px'
      >
        <ErrorImg src='/images/validation/icon-error.png' />
        <ErrorText>{L.s('page-expired')}</ErrorText>
      </Widget.Center>
    )
  }
}

const ErrorImg = styled.img`
  width: 100px;
  height: 100px;
`;

const ErrorText = styled.div`
  padding: 20px;
  font-size: 16px;
  color: #F84343;
`;

export default withPage(ExpiredPage);